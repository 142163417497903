import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppProvider from "./Provider";
import AppContext from "./Context";
import Header from "./components/header";
import Footer from "./components/footer";
import Product from "./routes/product";
import Cart from "./routes/cart";
import Checkout from "./routes/checkout";
import { StripeProvider } from "react-stripe-elements";
import { NotificationContainer } from "react-notifications";
import "../node_modules/reset-css/reset.css";
import "./global.css";
import "react-notifications/lib/notifications.css";
import { PulseLoader } from "react-spinners";
import styles from "./Styles";
import { StyleRoot } from "radium";
const App = () => (React.createElement(StripeProvider, { apiKey: "pk_test_nQrw3XVVOkBmLTIQjmFz9fyc" },
    React.createElement(Router, null,
        React.createElement(AppProvider, null,
            React.createElement(StyleRoot, null,
                React.createElement(React.Fragment, null,
                    React.createElement(Header, null),
                    React.createElement(Route, { exact: true, path: "/", component: Product }),
                    React.createElement(Route, { path: "/cart", component: Cart }),
                    React.createElement(Route, { path: "/checkout", component: Checkout }),
                    React.createElement(NotificationContainer, null),
                    React.createElement(Footer, null),
                    React.createElement(AppContext.Consumer, null, (context) => {
                        return (context.state.loading && (React.createElement("div", { style: styles.loading },
                            React.createElement(PulseLoader, null))));
                    })))))));
render(React.createElement(App, null), document.getElementById("root"));
