export default {
    container: {
        width: 1200,
        margin: "0 auto",
        maxWidth: "90%"
    },
    loading: {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.25)"
    }
};
