export default {
    container: {
        height: 100,
        backgroundColor: "#111111",
        display: "flex",
        boxShadow: "0px 2px 4px rgba(0,0,0,.18)"
    },
    innerContainer: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    logo: {
        display: "flex",
        alignItems: "center"
    },
    menu: {
        listStyleType: "none",
        display: "flex"
    },
    menuItem: {
        listStyleType: "none",
        color: "#ffffff",
        fontSize: 13,
        textTransform: "uppercase",
        fontWeight: 700,
        letterSpacing: 0.5,
        display: "flex",
        paddingLeft: 16,
        paddingRight: 16
    },
    menuItemLink: {
        color: "#ffffff",
        fontSize: 13,
        textDecoration: "none",
        display: "flex"
    },
    cartCount: {
        borderRadius: 50,
        width: 20,
        height: 20,
        fontSize: 10,
        backgroundColor: "rgb(189, 16, 24)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: -10,
        left: 2,
        position: "relative"
    }
};
