import * as React from "react";
import Container from "../../components/container";
import Header from "./header";
import Single from "./single";
import Sidebar from "./sidebar";
import AppContext from "../../Context";
import styles from "./styles";
export default class Cart extends React.Component {
    render() {
        return (React.createElement("div", { style: styles.container },
            React.createElement(Container, null,
                React.createElement("div", { style: styles.cartContainer },
                    React.createElement(AppContext.Consumer, null, (context) => {
                        if (context.state.cart.length > 0) {
                            return (React.createElement("div", { style: styles.cart },
                                React.createElement(Header, null),
                                React.createElement(React.Fragment, null, context.state.cart.map(item => (React.createElement(Single, { key: item.id, item: item }))))));
                        }
                        return (React.createElement("div", { style: styles.noItemsInCart },
                            React.createElement("h3", null, "No Items In Cart.")));
                    })),
                React.createElement(Sidebar, null))));
    }
}
