import * as React from "react";
import Select from "react-select";
import AppContext from "../../Context";
import Countries from "./countries";
import styles from "./styles";
export default class CountrySelect extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: "US"
        };
        this.updateValue = (value) => {
            this.setState({ value });
        };
        this.handleAutoFill = (e) => {
            console.log(e);
        };
    }
    render() {
        return (React.createElement(AppContext.Consumer, null, (context) => {
            const options = getOptions();
            return (React.createElement("div", { style: styles.container },
                React.createElement(Select, { clearable: false, style: this.props.style, menuStyle: this.props.menuStyle, menuContainerStyle: this.props.containerStyle, wrapperStyle: this.props.wrapperStyle, options: options, value: this.state.value, onChange: this.updateValue, simpleValue: true, name: "country", autoComplete: "country", onAutoFill: e => console.log(e), multi: false, autoSize: false, id: "country" })));
        }));
    }
}
const getOptions = () => {
    return Countries.map(c => {
        return { label: c.name, value: c.code };
    });
};
