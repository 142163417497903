import * as React from "react";
import Container from "../../components/container";
import AppContext from "../../Context";
import Sidebar from "../cart/sidebar";
import styles from "./styles";
import { Elements } from "react-stripe-elements";
import CheckoutForm from "./form";
export default class Checkout extends React.Component {
    render() {
        return (React.createElement(AppContext.Consumer, null, (context) => (React.createElement("div", { style: styles.container },
            React.createElement(Container, { style: styles.innerContainer },
                React.createElement("div", { style: styles.main },
                    React.createElement("h1", { style: styles.title }, "Checkout"),
                    React.createElement(Elements, null,
                        React.createElement(CheckoutForm, { onCalculateShipping: context.createOrder, onCheckout: context.payOrder }))),
                React.createElement(Sidebar, { hideCheckout: true }))))));
    }
}
