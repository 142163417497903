import * as React from "react";
import Select from "react-select";
import AppContext from "../../Context";
import States from "./states";
import styles from "./styles";
export default class StateSelect extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            value: "AL"
        };
        this.updateValue = (value) => {
            this.setState({ value });
        };
        this.handleAutoFill = (e) => {
            console.log(e);
        };
    }
    render() {
        return (React.createElement(AppContext.Consumer, null, (context) => {
            const options = getOptions(context.state.customer.address.country);
            return (React.createElement("div", { style: styles.container },
                React.createElement(Select, { clearable: false, style: this.props.style, menuStyle: this.props.menuStyle, menuContainerStyle: this.props.containerStyle, wrapperStyle: this.props.wrapperStyle, options: options, value: this.state.value, name: "state", onChange: this.updateValue, simpleValue: true, autoComplete: "state", onAutoFill: this.handleAutoFill, multi: false, autoSize: false, id: "state" })));
        }));
    }
}
const getOptions = (country) => {
    return States.filter(s => s.country === country).map(i => {
        return { label: i.name, value: i.short };
    });
};
