export default {
    container: {
        display: "inline-flex",
        border: "1px solid #ededed",
        borderRadius: 20,
        padding: "12px 10px",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row"
    },
    decrement: {
        cursor: "pointer",
        padding: "0 16px",
        color: "#c4c4c4",
        fontSize: 16
    },
    quantity: {
        padding: "0 16px",
        fontSize: 14
    },
    increment: {
        cursor: "pointer",
        padding: "0 16px",
        color: "#c4c4c4",
        fontSize: 16
    }
};
