import * as React from "react";
import styles from "./styles";
export default (props) => (React.createElement("div", { style: styles.section },
    React.createElement("div", { style: styles.sectionDescription },
        React.createElement("p", { style: styles.sectionDescriptionTitle }, "Account")),
    React.createElement("div", { style: styles.form },
        React.createElement("div", { style: styles.formGroup },
            React.createElement("label", { style: styles.label }, "Full Name"),
            React.createElement("input", { required: true, name: "name", style: styles.input })),
        React.createElement("div", { style: styles.formGroup },
            React.createElement("label", { style: styles.label }, "Email Address"),
            React.createElement("input", { required: true, name: "email", style: styles.input })))));
