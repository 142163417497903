export default {
    container: {
        backgroundColor: "#212121",
        paddingTop: 32,
        paddingBottom: 32
    },
    innerContainer: {
        display: "flex",
        justifyContent: "space-between",
        flex: 1
    },
    copyrightContainer: {
        display: "flex",
        flexDirection: "column"
    },
    credit: {
        color: "#f9f9f9",
        fontSize: 12
    },
    copyright: {
        color: "#ffffff",
        fontSize: 12,
        marginTop: 16
    },
    socialMediaContainer: {},
    socialMediaHeader: {
        color: "#f9f9f9"
    },
    menuContainer: { display: "flex" },
    menu: {},
    menuLeft: {
        marginRight: 16
    },
    menuRight: {},
    menuItem: {
        paddingTop: 4,
        paddingBottom: 4
    },
    menuItemLink: {
        color: "#ededed",
        fontSize: 12,
        textDecoration: "none"
    }
};
