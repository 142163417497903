import * as React from "react";
import styles from "./styles";
export default (props) => (React.createElement("div", { style: styles.header },
    React.createElement("div", { style: styles.itemsHeader },
        React.createElement("p", { style: styles.headerText }, "Items")),
    React.createElement("div", { style: styles.priceHeader },
        React.createElement("p", { style: styles.headerText }, "Price")),
    React.createElement("div", { style: styles.quantityHeader },
        React.createElement("p", { style: styles.headerText }, "Quantity")),
    React.createElement("div", { style: styles.totalHeader },
        React.createElement("p", { style: styles.headerText }, "Total"))));
