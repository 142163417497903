import * as React from "react";
import Container from "../container";
import { Link } from "react-router-dom";
import styles from "./styles";
export default (props) => (React.createElement("div", { style: styles.container },
    React.createElement(Container, null,
        React.createElement("div", { style: styles.innerContainer },
            React.createElement("div", { style: styles.copyrightContainer },
                React.createElement("p", null,
                    React.createElement("a", { href: "https://bhwebdev.com", style: styles.credit }, "E-Commerce Website by BH")),
                React.createElement("p", { style: styles.copyright }, "\u00A9 Burnley & Smith Racing. All Rights Reserved.")),
            React.createElement("div", { style: styles.socialMediaContainer },
                React.createElement("p", { style: styles.socialMediaHeader }, "Follow us on social media")),
            React.createElement("div", { style: styles.menuContainer },
                React.createElement("ul", { style: Object.assign({}, styles.menu, styles.menuLeft) },
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/", style: styles.menuItemLink }, "Shop")),
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/cart", style: styles.menuItemLink }, "Cart")),
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/contact", style: styles.menuItemLink }, "Contact"))),
                React.createElement("ul", { style: Object.assign({}, styles.menu, styles.menuRight) },
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/privacy-policy", style: styles.menuItemLink }, "Privacy Policy")),
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/terms-conditions", style: styles.menuItemLink }, "Terms & Conditions")),
                    React.createElement("li", { style: styles.menuItem },
                        React.createElement(Link, { to: "/return-policy", style: styles.menuItemLink }, "Returns"))))))));
