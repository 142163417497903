import * as React from "react";
import Container from "../../components/container";
import styles from "./styles";
import AppContext from "../../Context";
import { PulseLoader } from "react-spinners";
import QuantityInput from "../../components/quantity-input";
import Select from "react-select";
import Slider from "react-slick";
import "react-select/dist/react-select.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Radium from "radium";
class Product extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            quantity: 1
        };
        this.addItemToCart = (context) => {
            if (this.state.quantity > 0) {
                context.addItemsToCart({
                    id: context.state.selectedProduct.id,
                    qty: this.state.quantity
                });
            }
        };
        this.updateQuantity = e => {
            this.setState({ quantity: e.target.value });
        };
        this.decrementQuantity = () => {
            let { quantity } = this.state;
            if (quantity <= 1) {
                quantity = 1;
            }
            else {
                quantity -= 1;
            }
            this.setState({ quantity });
        };
        this.incrementQuantity = () => {
            const { quantity } = this.state;
            this.setState({ quantity: quantity + 1 });
        };
        this.handleChangeSize = (context, id) => {
            const { product } = context.state;
            let selected = product.skus.data.find(p => p.id === id);
            context.updateSelectedProduct(selected);
        };
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (React.createElement(Container, null,
            React.createElement(AppContext.Consumer, null, (context) => (React.createElement("div", { style: styles.container },
                React.createElement("div", { style: styles.productContainer }, context.state.product ? (React.createElement("div", { style: styles.product },
                    React.createElement("div", { style: styles.productImageContainer },
                        React.createElement(Slider, Object.assign({}, settings), context.state.product.images.map((image, index) => (React.createElement("div", { key: index },
                            React.createElement("img", { src: image })))))),
                    React.createElement("div", { style: styles.productInfo },
                        React.createElement("h3", { style: styles.category }, context.state.product
                            .metadata.category),
                        React.createElement("h1", { style: styles.name }, context.state.product.name),
                        React.createElement("p", { style: styles.price }, formatPrice(context.state
                            .selectedProduct.price)),
                        React.createElement("h2", { style: styles.caption }, context.state.product.caption),
                        React.createElement("div", { style: styles.quantityContainer },
                            React.createElement("p", { style: styles.quantityLabel },
                                "Select Quantity:",
                                " "),
                            React.createElement(QuantityInput, { onDecrement: this.decrementQuantity, onIncrement: this.incrementQuantity, quantity: this.state.quantity })),
                        React.createElement("div", { style: styles.sizeSelectContainer },
                            React.createElement("p", { style: styles.sizeSelectLabel },
                                "Select Size:",
                                " "),
                            React.createElement(Select, { clearable: false, wrapperStyle: {
                                    // flex: 1,
                                    width: 141,
                                    height: 42
                                }, style: {
                                    borderColor: "#ededed",
                                    borderRadius: 20,
                                    paddingLeft: 16
                                }, value: context.state
                                    .selectedProduct.id, onChange: product => this.handleChangeSize(context, product.value), options: context.state.product.skus.data.map(p => ({
                                    value: p.id,
                                    label: p.attributes
                                        .size
                                })) })),
                        React.createElement("div", { style: styles.addToCartButton, onClick: () => this.addItemToCart(context) },
                            React.createElement("p", { style: styles.addToCartButtonText }, "Add to cart"))))) : (React.createElement("div", { style: styles.loadingContainer },
                    React.createElement(PulseLoader, null)))))))));
    }
}
export default Radium(Product);
const formatPrice = (cents) => {
    return `$${(cents / 100).toFixed(2)}`;
};
