import * as React from "react";
import { CardElement } from "react-stripe-elements";
import styles from "./styles";
export default class CardForm extends React.Component {
    render() {
        return (React.createElement("div", { style: styles.section },
            React.createElement("div", { style: styles.sectionDescription },
                React.createElement("p", { style: styles.sectionDescriptionTitle }, "Payment Information")),
            React.createElement("div", { style: styles.form },
                React.createElement("div", { style: styles.formGroup },
                    React.createElement(CardElement, { style: { base: { fontSize: "18px" } } })))));
    }
}
