import * as React from "react";
import styles from "./styles";
import Container from "../container";
import AppContext from "../../Context";
import { Link } from "react-router-dom";
export default (props) => (React.createElement("div", { style: styles.container },
    React.createElement(Container, { style: styles.innerContainer },
        React.createElement("div", { style: styles.logo },
            React.createElement("img", { src: "images/burnley-smith-no-bg.png", width: 150 })),
        React.createElement(AppContext.Consumer, null, (context) => (React.createElement("ul", { style: styles.menu },
            React.createElement("li", { style: styles.menuItem },
                React.createElement(Link, { to: "/", style: styles.menuItemLink }, "Shop")),
            React.createElement("li", { style: styles.menuItem },
                React.createElement(Link, { to: "/cart", style: styles.menuItemLink },
                    "Cart",
                    context.state.cart.length > 0 && (React.createElement("div", { style: styles.cartCount }, getCartCount(context.state.cart)))))))))));
const getCartCount = (cart) => {
    let count = 0;
    cart.forEach(c => {
        count += c.qty;
    });
    return count;
};
