import * as React from "react";
import AppContext from "../../Context";
import { Link } from "react-router-dom";
import styles from "./styles";
const Sidebar = (props) => {
    return (React.createElement(AppContext.Consumer, null, (context) => {
        if (context.state.cart.length > 0) {
            const subtotal = getCartTotal(context.state.cart, context.state.product);
            const tax = getTaxAmount(subtotal, 0.06);
            let shippingAmount = 0;
            if (context.state.order) {
                const selected = context.state.order.shipping_methods.find(m => m.id ===
                    context.state.order.selected_shipping_method);
                shippingAmount = selected.amount / 100;
            }
            const total = subtotal + tax + shippingAmount;
            return (React.createElement("div", { style: styles.sidebarContainer },
                React.createElement("p", { style: styles.sidebarHeader }, "Cart Totals"),
                React.createElement("div", { style: styles.sidebarContent },
                    React.createElement("div", { style: styles.sidebarContentSection },
                        React.createElement("p", { style: styles.sidebarContentLabel }, "Subtotal:"),
                        React.createElement("p", { style: styles.sidebarContentValue }, `$ ${subtotal.toFixed(2)}`)),
                    context.state.order && (React.createElement("div", { style: styles.sidebarContentSection },
                        React.createElement("p", { style: styles.sidebarContentLabel }, "Shipping:"),
                        React.createElement("p", { style: styles.sidebarContentValue }, `$ ${shippingAmount.toFixed(2)}`))),
                    React.createElement("div", { style: styles.sidebarContentSection },
                        React.createElement("p", { style: styles.sidebarContentLabel }, "Tax:"),
                        React.createElement("p", { style: styles.sidebarContentValue }, `$ ${tax.toFixed(2)}`)),
                    React.createElement("div", { style: Object.assign({}, styles.sidebarContentSection, { borderBottom: 0 }) },
                        React.createElement("p", { style: Object.assign({}, styles.sidebarContentLabel, styles.sidebarTotalLabel) }, "Total:"),
                        React.createElement("p", { style: styles.sidebarContentValue }, `$ ${total.toFixed(2)}`))),
                !props.hideCheckout && (React.createElement("div", { style: styles.button },
                    React.createElement(Link, { to: "/checkout", style: styles.buttonLink }, "Checkout")))));
        }
        return null;
    }));
};
Sidebar.defaultProps = {
    hideCheckout: false
};
export default Sidebar;
const getCartTotal = (cart, product) => {
    let total = 0;
    if (cart && product) {
        cart.forEach(c => {
            total += product.skus.data.find(p => p.id === c.id).price * c.qty;
        });
    }
    return total / 100;
};
const getTaxAmount = (total, tax) => {
    return total * tax;
};
