import * as React from "react";
import AppContext from "../../Context";
import QuantityInput from "../../components/quantity-input";
import styles from "./styles";
export default (props) => {
    const { item } = props;
    return (React.createElement(AppContext.Consumer, null, (context) => {
        if (context.state.product) {
            const product = getItemFromProduct(item.id, context);
            return (React.createElement("div", { style: styles.singleContainer },
                React.createElement("div", { style: styles.itemsBody },
                    React.createElement("div", { style: styles.singleRowImage },
                        React.createElement("img", { src: product.image, width: 150 })),
                    React.createElement("div", { style: styles.singleRowTitle },
                        React.createElement("p", { style: styles.productTitle }, context.state.product.name),
                        React.createElement("div", { style: styles.productMeta },
                            React.createElement("p", { style: styles.productSize },
                                React.createElement("span", { style: styles.label },
                                    "Size:",
                                    " "),
                                product.attributes.size)))),
                React.createElement("div", { style: styles.priceBody },
                    React.createElement("p", { style: styles.price }, `$ ${(product.price / 100).toFixed(2)}`)),
                React.createElement("div", { style: styles.quantityBody },
                    React.createElement(QuantityInput, { onIncrement: () => context.updateCartQuantity(item.id, item.qty + 1), onDecrement: () => context.updateCartQuantity(item.id, item.qty - 1 <= 1 ? 1 : item.qty - 1), quantity: item.qty })),
                React.createElement("div", { style: styles.totalBody },
                    React.createElement("p", { style: styles.total }, `$ ${(item.qty *
                        (product.price / 100)).toFixed(2)}`)),
                React.createElement("div", { style: styles.removeBody },
                    React.createElement("a", { style: styles.remove, onClick: () => context.removeItemsFromCart(item.id) }, "X"))));
        }
        return null;
    }));
};
const getItemFromProduct = (id, context) => {
    const sku = context.state.product.skus.data.find(s => s.id === id);
    return sku;
};
