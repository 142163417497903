var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import AppContext from "./Context";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
class AppProvider extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            product: null,
            selectedProduct: null,
            cart: [],
            customer: {
                email: "",
                name: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    postal_code: "",
                    country: "US",
                    state: "AL"
                }
            },
            order: null,
            loading: false
        };
        this.updateSelectedProduct = (product) => {
            this.setState({ selectedProduct: product });
        };
        this.fetchCart = () => {
            const cart = localStorage.getItem("cart");
            let cartJSON = JSON.parse(cart);
            if (!cartJSON) {
                cartJSON = [];
            }
            this.setState({ cart: cartJSON });
        };
        this.addItemsToCart = (item) => {
            const cart = [...this.state.cart];
            const existing = cart.find(c => c.id === item.id);
            if (existing) {
                existing.qty += item.qty;
            }
            else {
                cart.push(item);
            }
            localStorage.setItem("cart", JSON.stringify(cart));
            this.setState({ cart }, () => {
                NotificationManager.success("Checkout Now", "Item(s) added to cart!", 5000, () => this.props.history.push("/cart"));
            });
        };
        this.removeItemsFromCart = (id) => {
            const { cart } = this.state;
            const updated = [...cart];
            const index = cart.findIndex(p => p.id === id);
            updated.splice(index, 1);
            localStorage.setItem("cart", JSON.stringify(updated));
            this.setState({ cart: updated });
        };
        this.updateCartQuantity = (id, qty) => {
            const { cart } = this.state;
            const updated = [...cart];
            if (qty <= 1) {
                qty = 1;
            }
            updated.find(p => p.id === id).qty = qty;
            localStorage.setItem("cart", JSON.stringify(updated));
            this.setState({ cart: updated });
        };
        this.createOrder = (customer) => {
            this.toggleLoading(true);
            const { cart } = this.state;
            const items = cart.map(c => ({
                type: "sku",
                parent: c.id,
                quantity: c.qty
            }));
            const body = {
                currency: "usd",
                email: customer.email,
                items,
                shipping: {
                    name: customer.name,
                    address: customer.address
                }
            };
            fetch("https://us-central1-ed-burnley-racing-shop.cloudfunctions.net/createOrder", {
                body: JSON.stringify(body),
                method: "post"
            })
                .then(res => {
                return res.json();
            })
                .then(json => {
                this.setState({ order: json }, () => {
                    this.setDefaultShipping();
                    this.toggleLoading(false);
                });
            })
                .catch(e => {
                console.log("Error: ", e);
                this.toggleLoading(false);
            });
        };
        this.updateOrder = () => {
            this.toggleLoading(true);
            const { order } = this.state;
            const body = {
                order
            };
            fetch("https://us-central1-ed-burnley-racing-shop.cloudfunctions.net/updateOrder", {
                body: JSON.stringify(body),
                method: "post"
            })
                .then(res => {
                return res.json();
            })
                .then(json => {
                this.setState({ order: json }, () => {
                    this.toggleLoading(false);
                });
            })
                .catch(e => {
                console.log("Error: ", e);
                this.toggleLoading(false);
            });
        };
        this.payOrder = (id, source) => __awaiter(this, void 0, void 0, function* () {
            this.toggleLoading(true);
            yield this.updateOrder();
            const body = {
                id,
                source
            };
            fetch("https://us-central1-ed-burnley-racing-shop.cloudfunctions.net/payOrder", {
                body: JSON.stringify(body),
                method: "post"
            })
                .then(res => {
                console.log(res);
                return res.json();
            })
                .then(json => {
                console.log("order paid: ", json);
                this.setState({ order: json }, () => {
                    this.toggleLoading(false);
                });
            })
                .catch(e => {
                console.log("Error: ", e);
                this.toggleLoading(false);
            });
        });
        this.changeShippingMethod = (id) => {
            const order = Object.assign({}, this.state.order);
            order.selected_shipping_method = id;
            this.setState({ order });
        };
        this.setDefaultShipping = () => {
            const order = Object.assign({}, this.state.order);
            const methods = order.shipping_methods.sort((a, b) => a.amount - b.amount);
            order.selected_shipping_method = methods[0].id;
            this.setState({ order });
        };
        this.toggleLoading = (loading) => {
            this.setState({ loading });
        };
    }
    componentDidMount() {
        fetch("https://us-central1-ed-burnley-racing-shop.cloudfunctions.net/getProduct")
            .then(res => {
            return res.json();
        })
            .then((json) => {
            const selectedSku = json.skus.data[0];
            console.log(json);
            this.setState({ product: json, selectedProduct: selectedSku });
        })
            .catch(e => console.log("Error: ", e));
        this.fetchCart();
    }
    render() {
        const { state } = this;
        return (React.createElement(AppContext.Provider, { value: {
                state,
                updateSelectedProduct: this.updateSelectedProduct,
                addItemsToCart: this.addItemsToCart,
                updateCartQuantity: this.updateCartQuantity,
                createOrder: this.createOrder,
                payOrder: this.payOrder,
                removeItemsFromCart: this.removeItemsFromCart,
                changeShippingMethod: this.changeShippingMethod,
                toggleLoading: this.toggleLoading
            } }, this.props.children));
    }
}
// export default withRouter(AppProvider)
export default withRouter(AppProvider);
