import * as React from "react";
import Customer from "./customer";
import Card from "./card";
import Address from "./address";
import { injectStripe } from "react-stripe-elements";
import styles from "./styles";
import Shipping from "./shipping";
import AppContext from "../../Context";
class CheckoutForm extends React.Component {
    constructor() {
        super(...arguments);
        this.handleSubmit = e => {
            e.preventDefault();
            const { name, email, address, address2, city, state, country, zip, orderId } = e.target.elements;
            const button = Object.keys(e.target.elements).find(el => {
                return e.target.elements[el].name === "submit";
            });
            const action = e.target.elements[button].value;
            if (action === "purchase") {
                this.props.stripe
                    .createToken({ name: name.value })
                    .then(({ token }) => {
                    console.log(JSON.stringify(token));
                    this.props.onCheckout(orderId.value, token.id);
                });
            }
            else if (action === "calculateShipping") {
                this.props.onCalculateShipping({
                    name: name.value,
                    email: email.value,
                    address: {
                        line1: address.value,
                        line2: address2.value,
                        city: city.value,
                        state: state[0].value,
                        country: country[0].value,
                        postal_code: zip.value
                    }
                });
            }
        };
    }
    render() {
        return (React.createElement(AppContext.Consumer, null, (context) => (React.createElement("form", { onSubmit: this.handleSubmit },
            React.createElement(Customer, null),
            React.createElement(Address, null),
            context.state.order && React.createElement(Card, null),
            context.state.order && React.createElement(Shipping, null),
            context.state.order && (React.createElement("input", { name: "orderId", value: context.state.order.id, type: "hidden" })),
            React.createElement("div", { style: styles.purchaseButtonContainer }, !context.state.order ? (React.createElement("button", { name: "submit", style: styles.purchaseButton, value: "calculateShipping" }, "Calculate Shipping")) : (React.createElement("button", { name: "submit", style: styles.purchaseButton, value: "purchase" }, "Purchase")))))));
    }
}
export default injectStripe(CheckoutForm);
