import * as React from "react";
import AppContext from "../../Context";
import styles from "./styles";
import { convertShippingDateToReadable } from "../../util/date-parser";
export default class Shipping extends React.Component {
    render() {
        return (React.createElement(AppContext.Consumer, null, (context) => {
            return (React.createElement("div", { style: styles.section },
                React.createElement("div", { style: styles.sectionDescription },
                    React.createElement("p", { style: styles.sectionDescriptionTitle }, "Shipping")),
                React.createElement("div", { style: styles.form }, context.state.order.shipping_methods
                    .sort((a, b) => a.amount - b.amount)
                    .map((m, i) => {
                    return (React.createElement(SingleMethod, { key: m.id, method: m, selected: m.id ===
                            context.state.order
                                .selected_shipping_method }));
                }))));
        }));
    }
}
const SingleMethod = (props) => (React.createElement("div", { style: styles.formGroup },
    React.createElement("label", null,
        React.createElement("input", { type: "radio", name: "shipping", value: props.method.amount, required: true, onChange: e => console.log(e), checked: props.selected }),
        React.createElement("span", { style: styles.shippingDescription },
            props.method.description,
            " -"),
        " ",
        React.createElement("span", { style: styles.shippingAmount },
            "$",
            (props.method.amount / 100).toFixed(2)),
        React.createElement("br", null),
        React.createElement("span", { style: styles.shippingETA },
            "Estimated Arrival:",
            " ",
            convertShippingDateToReadable(props.method.delivery_estimate.date)))));
