import * as React from "react";
import styles from "./styles";
import CountrySelect from "../../components/country-select";
import StateSelect from "../../components/state-select";
export default (props) => (React.createElement("div", { style: styles.section },
    React.createElement("div", { style: styles.sectionDescription },
        React.createElement("p", { style: styles.sectionDescriptionTitle }, "Shipping Address")),
    React.createElement("div", { style: styles.form },
        React.createElement("div", { style: styles.formGroup },
            React.createElement("label", { style: styles.label }, "Street Address"),
            React.createElement("input", { required: true, name: "address", style: styles.input })),
        React.createElement("div", { style: styles.formGroup },
            React.createElement("label", { style: styles.label }, "Street Address Line 2"),
            React.createElement("input", { name: "address2", style: styles.input })),
        React.createElement("div", { style: styles.inline },
            React.createElement("div", { style: Object.assign({}, styles.formGroup, styles.inlineFormGroup) },
                React.createElement("label", { style: styles.label }, "City"),
                React.createElement("input", { name: "city", required: true, style: Object.assign({}, styles.input, styles.inputInline) })),
            React.createElement("div", { style: Object.assign({}, styles.formGroup, styles.inlineFormGroup) },
                React.createElement("label", { style: styles.label }, "State/Province"),
                React.createElement(StateSelect, { style: Object.assign({}, styles.input, styles.inputInline, styles.select), menuStyle: styles.selectMenuStyle, wrapperStyle: styles.selectWrapperStyle, containerStyle: styles.selectMenuContainerStyle }))),
        React.createElement("div", { style: styles.inline },
            React.createElement("div", { style: Object.assign({}, styles.formGroup, styles.inlineFormGroup) },
                React.createElement("label", { style: styles.label }, "Zip/Postal Code"),
                React.createElement("input", { name: "zip", required: true, style: Object.assign({}, styles.input, styles.inputInline) })),
            React.createElement("div", { style: Object.assign({}, styles.formGroup, styles.inlineFormGroup) },
                React.createElement("label", { style: styles.label }, "Country"),
                React.createElement(CountrySelect, { style: Object.assign({}, styles.input, styles.inputInline, styles.select), menuStyle: styles.selectMenuStyle, wrapperStyle: styles.selectWrapperStyle, containerStyle: styles.selectMenuContainerStyle }))))));
