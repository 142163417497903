export default {
    container: {
        paddingTop: 60,
        paddingBottom: 60,
        flex: 1,
        maxWidth: "100%"
    },
    productContainer: {},
    product: {
        display: "flex",
        "@media (max-width: 768px)": {
            flexDirection: "column"
        }
    },
    productImageContainer: {
        maxWidth: "50%",
        width: "50%",
        "@media (max-width: 768px)": {
            width: "100%",
            maxWidth: "100%"
        }
    },
    productInfo: {
        justifyContent: "center",
        flexDirection: "column",
        paddingLeft: 100,
        paddingTop: 80
    },
    category: {
        fontSize: 14,
        fontWeight: 900,
        textTransform: "uppercase",
        paddingBottom: 8
    },
    name: {
        color: "#212121",
        fontSize: 40,
        fontWeight: 900,
        paddingBottom: 16
    },
    caption: {
        color: "#666666",
        fontSize: 14,
        paddingBottom: 24,
        paddingTop: 16
    },
    price: {
        color: "#555555",
        fontWeight: 900,
        padding: "8px 0",
        fontSize: 32
    },
    addToCartButton: {
        backgroundColor: "#00B233",
        borderRadius: 20,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 32,
        cursor: "pointer",
        flex: 1
    },
    addToCartButtonText: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: 700,
        textTransform: "uppercase",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    quantityContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 16,
        paddingBottom: 16
    },
    quantityLabel: {
        fontSize: 14
    },
    sizeSelectContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    sizeSelectLabel: {
        fontSize: 14,
        flex: 1
    },
    sizeOption: {},
    loadingContainer: {
        minHeight: 640,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
};
