export default {
    container: {
        display: "flex",
        flex: 1,
        paddingTop: 60,
        paddingBottom: 60
    },
    cartContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    cart: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginRight: 24
    },
    header: {
        display: "flex",
        borderBottomWidth: 2,
        borderBottomColor: "#ededed",
        borderStyle: "solid",
        paddingBottom: 20
    },
    itemsHeader: {
        width: "50%"
    },
    itemsBody: {
        width: "50%",
        display: "flex"
    },
    quantityHeader: {
        width: "20%"
    },
    quantityBody: {
        width: "20%",
        paddingTop: 16
    },
    priceHeader: {
        width: "12.5%"
    },
    priceBody: {
        width: "12.5%",
        paddingTop: 16
    },
    totalHeader: {
        width: "12.5%"
    },
    totalBody: {
        width: "12.5%",
        paddingTop: 16
    },
    removeBody: {
        width: "6%",
        paddingTop: 16
    },
    remove: {
        color: "rgb(189, 16, 24)",
        cursor: "pointer"
    },
    headerText: {
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 600
    },
    singleContainer: {
        paddingTop: 32,
        paddingBottom: 32,
        borderBottomWidth: 1,
        borderBottomColor: "#ededed",
        borderBottomStyle: "solid",
        display: "flex"
    },
    singleRowImage: {},
    singleRowTitle: {
        paddingLeft: 32,
        paddingTop: 16
    },
    productTitle: {
        fontSize: 16,
        fontWeight: 700
    },
    productMeta: {
        paddingTop: 24
    },
    productSize: {
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 600
    },
    label: {
        fontSize: 12,
        textTransform: "uppercase",
        fontWeight: 400
    },
    price: {},
    total: {},
    sidebarContainer: {
        paddingTop: 16,
        display: "flex",
        width: "25%",
        flexDirection: "column"
    },
    sidebarHeader: {
        fontSize: 18,
        fontWeight: 600,
        paddingBottom: 12
    },
    sidebarContent: {
        backgroundColor: "#f9f9f9",
        borderRadius: 3,
        borderWidth: 1,
        borderColor: "#d4d4d4",
        borderStyle: "solid",
        display: "flex",
        flexDirection: "column"
    },
    sidebarContentSection: {
        borderBottomWidth: 1,
        borderBottomColor: "#d4d4d4",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "space-between",
        padding: 12
    },
    sidebarContentLabel: {
        fontSize: 14,
        color: "#666666"
    },
    sidebarContentValue: {},
    sidebarTotalLabel: {
        fontWeight: 700,
        textTransform: "uppercase"
    },
    button: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderRadius: 3,
        backgroundColor: "#00B233",
        marginTop: 16
    },
    buttonLink: {
        color: "#ffffff",
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: 14,
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1
    },
    noItemsInCart: {}
};
