export default {
    container: {
        flex: 1,
        paddingTop: 32,
        paddingBottom: 32
    },
    innerContainer: {
        display: "flex",
        flexDirection: "row"
    },
    main: {
        width: "75%",
        marginRight: 24
    },
    title: {
        borderBottomWidth: 1,
        borderBottomColor: "#ededed",
        borderStyle: "solid",
        fontSize: 24,
        paddingBottom: 12,
        color: "#212121",
        fontWeight: 700
    },
    accountContainer: {},
    sectionDescription: {
        width: "33%"
    },
    section: {
        display: "flex",
        paddingTop: 24,
        paddingBottom: 24,
        borderBottomWidth: 1,
        borderBottomColor: "#ededed",
        borderStyle: "solid"
    },
    form: {
        width: "75%"
    },
    sectionDescriptionTitle: {
        fontSize: 16,
        color: "#212121",
        fontWeight: 500
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        width: 400
    },
    formGroup: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 24,
        width: 400
    },
    inlineFormGroup: {
        width: 185
    },
    label: {
        fontSize: 11
    },
    input: {
        borderRadius: 3,
        borderWidth: 1,
        borderColor: "#d6d6d6",
        borderStyle: "solid",
        height: 36,
        width: "100%",
        marginTop: 12,
        backgroundColor: "#f9f9f9",
        fontSize: 14,
        paddingLeft: 8
    },
    select: { height: 40 },
    selectMenuStyle: {
        fontSize: 14
    },
    selectMenuContainerStyle: {},
    selectWrapperStyle: {
        width: 193
        // marginLeft: 8
    },
    inputInline: {},
    purchaseButton: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderRadius: 3,
        backgroundColor: "#00B233",
        marginTop: 16,
        color: "#ffffff",
        fontWeight: 600,
        fontSize: 14,
        textDecoration: "none",
        borderWidth: 0,
        boxShadow: "0px 2px 4px rgba(0,0,0,.11)",
        cursor: "pointer"
    },
    purchaseButtonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    singleShippingMethod: {},
    shippingAmount: {
        fontWeight: 700,
        fontSize: 13
    },
    shippingDescription: {
        paddingLeft: 8,
        fontSize: 13
    },
    shippingETA: {
        fontStyle: "italic",
        fontSize: 12,
        paddingLeft: 24,
        color: "#777777"
    }
};
